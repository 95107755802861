import { RoundMenu } from "components/atoms/Icons/menu";
import { NightWatch } from "components/atoms/Icons/primary";
import { useState } from "react";
import { Desktop } from "./navbar/desktop";
import { Mobile } from "./navbar/mobile";

export const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="flex items-center w-screen justify-between flex-wrap p-6">
      <div className="flex items-center gap-4 flex-shrink-0 text-primary mr-6">
        <NightWatch />
        <p className="font-bold">WatchPrice</p>
      </div>

      <Desktop className="hidden md:block" />

      <div className="md:hidden">
        <RoundMenu onClick={() => setOpen(true)} />
      </div>
      <Mobile setOpen={setOpen} isOpen={open} className="md:hidden" />
    </nav>
  );
};
