import axios from "axios";
import { getFormattedToken } from "helpers/token/post.token";
import { getErrorMessage } from "./error/fetch.error";

axios.defaults.baseURL = process.env.REACT_APP_API;

export const axiosSecuredRequest = async (axiosParams) => {
  try {
    const dto = await axios.request({ ...axiosParams, ...getFormattedToken() });
    return { status: "success", response: dto.data };
  } catch (error) {
    console.error(error);
    const message = error.response
      ? error.response.data.error
      : "AN_ERROR_HAS_OCCURRED";
    return {
      status: "error",
      response: getErrorMessage(message),
      code: message,
    };
  }
};
