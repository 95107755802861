import Cookies from "universal-cookie";
import { getToken } from "./setter.token";

export function getFormattedToken(contentType = false, jsonData = false) {
  if (getToken() && !contentType && !jsonData) {
    return {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
  } else if (getToken() && contentType && !jsonData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data; boundary=something",
        Authorization: `Bearer ${getToken()}`,
      },
    };
  } else if (getToken() && !contentType && jsonData) {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
  }
}
