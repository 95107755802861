export const DefaultTitle = (props) => {
  return (
    <h1 className={`text-xl font-bold ${props.className}`}>{props.children}</h1>
  );
};

export const DefaultText = (props) => {
  return (
    <p className={`text-sm font-medium ${props.className}`}>{props.children}</p>
  );
};

export const DefaultSubtext = (props) => {
  return (
    <p className={`text-xs font-medium text-grey ${props.className}`}>
      {props.children}
    </p>
  );
};
