export const Items = (props) => {
  return (
    <li
      {...props}
      className={`flex items-center gap-2 ${props.className} group`}
    >
      {props.children}
    </li>
  );
};
