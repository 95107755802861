import { Footer, Navbar } from "components/molecules";

export const DefaultTemplate = ({ children, className }) => {
  return (
    <div className="h-screen flex justify-center overflow-hidden">
      <div
        className={`w-screen min-h-full flex flex-col justify-between bg-white border-x`}
      >
        <Navbar />
        <div className={`w-full flex-1 ${className} gap-4`}>{children}</div>
        <Footer />
      </div>
    </div>
  );
};
