export const DefaultButton = (props) => {
  return (
    <button
      className={`bg-primary text-white font-semibold w-[250px] h-[60px] rounded-lg ${props.className}`}
    >
      {props.children}
    </button>
  );
};

export const LightButton = (props) => {
  return (
    <button
      className={`bg-grey_light text-sm text-purple_light font-medium w-[150px] h-[35px] rounded-lg shadow-lg hover:bg-purple_light hover:text-grey_light transition-all ${props.className}`}
    >
      {props.children}
    </button>
  );
};
