import Cookies from "universal-cookie";

export const getToken = () => {
  const cookies = new Cookies();
  const token = cookies.get("auth_wp_access_token");
  return token;
};

export const setToken = (value) => {
  const cookies = new Cookies();
  cookies.set("auth_wp_access_token", value, {
    path: "/",
    expires: new Date(Date.now() + 60 * 60 * 24 * 7),
    secure: false, //<------ set to true if your using https
    sameSite: "strict",
  });
};
