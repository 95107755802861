import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";

// Context
import { UserContext } from "context/user.context";

import Login from "features/unsecured/authentication/login.page";
import { checkToken } from "api/authentication/login.user";
import { HomePage } from "features/unsecured/home";

export default function RootContainer() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkIfUserIsLoggedIn();
  }, []);

  const checkIfUserIsLoggedIn = async () => {
    setUser(await checkToken());
  };

  const handleUserConnection = () => {};

  return (
    <UserContext.Provider value={(user, setUser)}>
      {
        // <Routes />
        user ? (
          <Routes>
            <Route path="/" element={<h1>Home</h1>} />
          </Routes>
        ) : (
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        )
      }
    </UserContext.Provider>
  );
}
