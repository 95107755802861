import { useState } from "react";
import {
  Account,
  Logout,
  Minus,
  Shield,
  Start,
} from "components/atoms/Icons/menu";
import { Link } from "react-router-dom";
import { Items } from "components/atoms/MenuItem";

export const Desktop = ({ className }) => {
  const [connected, setConnected] = useState(false);
  return (
    <div className={className}>
      {connected ? (
        <ul className="flex h-full justify-center font-semibold text-primary md:gap-4 lg:gap-12">
          <Items>
            <Account />
            <Link to="/">Mon compte</Link>
          </Items>
          <Items>
            <Start />
            <Link to="/">Commencer une analyse</Link>
          </Items>
          <Items>
            <Logout />
            <Link to="/">Se déconnecter</Link>
          </Items>
        </ul>
      ) : (
        <ul className="flex h-full justify-center font-semibold text-primary gap-16">
          <Items>
            <Shield />
            <Link to="/"> Authentification</Link>
          </Items>
          <Items>
            <Start />
            <Link to="/">Commencer une analyse</Link>
          </Items>
        </ul>
      )}
    </div>
  );
};
