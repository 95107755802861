import { axiosSecuredRequest } from "helpers/axios.request";
import { getToken } from "helpers/token/setter.token";

export const checkToken = async () => {
  try {
    const data = await axiosSecuredRequest({
      method: "POST",
      url: "/api/auth/check",
      data: {
        token: getToken(),
      },
    });
    return data.user;
  } catch (error) {
    console.error("coucou", error);
  }
};
