import { DefaultButton } from "components/atoms";
import { LightButton } from "components/atoms/Button";
import { DefaultCard } from "components/atoms/Card";
import { RocketLaunch } from "components/atoms/Icons/launch";
import {
  DefaultSubtext,
  DefaultText,
  DefaultTitle,
} from "components/atoms/Text";
import { DefaultTemplate } from "components/organisms";

export const HomePage = () => {
  return (
    <DefaultTemplate className="flex flex-col justify-center gap-14 items-center">
      <div className="text-center">
        <DefaultTitle className="mb-10">
          Réalisez votre étude <br /> de marché rapidement
        </DefaultTitle>
        <DefaultText>
          Vous achetez ou vendez un produit d'occasion ? <br />
          Faites votre étude de marché en un clique !
        </DefaultText>
      </div>
      <DefaultButton className="relative flex items-center justify-center gap-2">
        <span className="animate-ping -right-1 -top-1 absolute inline-flex h-3 w-3 rounded-full bg-primary opacity-75" />
        <span className="-right-1 -top-1 absolute inline-flex h-3 w-3 rounded-full bg-blue_dark border-2 border-primary" />
        Commencer <RocketLaunch />
      </DefaultButton>
      <DefaultCard className="flex flex-col items-center justify-center text-left px-6 h-[250px] w-5/6">
        <div className="h-2/3 flex flex-col justify-around">
          <DefaultText>
            {">"} Nous récupérons le prix moyen de l'article que vous recherchez
            en fonction de vos paramètres.
          </DefaultText>
          <DefaultText>
            {">"} Récupérer ces informations pour aller plus vite dans
            l'estimation de votre bien, ou dans le choix de votre futur achat.
          </DefaultText>
        </div>
        <LightButton className="mt-2">En savoir plus</LightButton>
      </DefaultCard>
    </DefaultTemplate>
  );
};
