// System
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import RootContainer from "router";
import * as serviceWorker from "./serviceWorker";

// Styles
import "./index.css";

ReactDOM.render(
  <BrowserRouter>
    <RootContainer />
  </BrowserRouter>,
  document.getElementById("root")
);

// change to register() for active PWA
serviceWorker.unregister();
