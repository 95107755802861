import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Account,
  Logout,
  Minus,
  Shield,
  Start,
} from "components/atoms/Icons/menu";
import { Items } from "components/atoms/MenuItem";

export const Mobile = ({ setOpen, isOpen, className }) => {
  const [connected, setConnected] = useState(false);
  return (
    <>
      <div
        className={`fixed z-50 w-screen rounded-t-lg bg-white bottom-0 left-0 flex flex-col items-center text-primary ${
          isOpen ? "h-[200px]" : "h-0"
        } transition-all ${className}`}
      >
        {isOpen && (
          <>
            <Minus className="mt-4" onClick={() => setOpen(false)} />
            {connected ? (
              <ul className="flex flex-col h-full justify-center font-semibold text-primary gap-4 w-2/3 mb-4">
                <Items>
                  <Account />
                  <Link to="/">Mon compte</Link>
                </Items>
                <Items>
                  <Start />
                  <Link to="/">Commencer une analyse</Link>
                </Items>
                <Items>
                  <Logout />
                  <Link to="/">Se déconnecter</Link>
                </Items>
              </ul>
            ) : (
              <ul className="flex flex-col h-full justify-center font-semibold text-primary gap-4 w-2/3 mb-4">
                <Items>
                  <Shield />
                  <Link to="/"> Authentification</Link>
                </Items>
                <Items>
                  <Start />
                  <Link to="/">Commencer une analyse</Link>
                </Items>
              </ul>
            )}
          </>
        )}
      </div>
      <div
        className={`h-screen w-screen md:hidden bg-black left-0 top-0 opacity-50 z-10 ${
          isOpen ? "fixed" : "hidden"
        }`}
        onClick={() => setOpen(false)}
      />
    </>
  );
};
