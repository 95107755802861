import { FooterNightWatch, NightWatch } from "components/atoms/Icons/primary";

export const Footer = () => {
  return (
    <footer className="bottom-0 w-full overflow-hidden mt-7">
      <div className="flex items-center ml-5 justify-between text-center">
        <div className="w-full overflow-hidden">
          <ul className="flex justify-center gap-6 w-full gap-3 text-xs text-grey font-medium">
            <li>
              <a href="">Comment ça marche ?</a>
            </li>
            <li>
              <a href="">Nous contacter</a>
            </li>
            <li>
              <a href="">Mentions légales</a>
            </li>
            <li>
              <a href="">Conditions générales</a>
            </li>
          </ul>
          <p className="text-[10px] text-grey my-5">
            WatchPrice - version 0.0.1 - 2021/2022
          </p>
        </div>
        {/*<NightWatch className="text-grey opacity-40" /> */}
      </div>
    </footer>
  );
};
